//(fixthis)
import SubHeader from "@/components/SubHeader";
// import dvTable from "@/components/devliumComponent/field-table/dv-table";
import Button from "@/components/button";
import Sinput from "@/components/input";
import NeoPagination from "@/components/pagination";
import Loader from "@/components/loader";
import LoaderFull from "@/components/loader-full";
// import { mapActions, mapGetters } from "vuex"
import modalContent from "@/components/modal-content";
import axios from "@/axios";
import { FETCH_FIELDS } from "@/modules/fields-admin/store";
import Dropdown from "@shared/components/dropdown-base";
import DelviumLoader from "@shared/loader";
import FieldChecksModal from "../components/fieldChecksModal.vue";
import FieldBlocksModal from "../components/fieldBlocksModal.vue";
import Table from "@shared/dashboard-components/table-large";
import { onlyUnique } from "@/plugins/utils.js";
import { uuid } from "vue-uuid";
import { checkPermission } from "@shared/utils/functions"
import { DateTime } from "luxon";
import { FormatDateNew } from "@/plugins/functions"

export default {
    name: "field-table",
    components: {
        SubHeader,
        // dvTable,
        Button,
        NeoPagination,
        Loader,
        Sinput,
        "loader-full": LoaderFull,
        modalContent,
        DelviumLoader,
        Dropdown,
        FieldChecksModal,
        Table,
        FieldBlocksModal,
    },
    created() {
        return this.$route.path;
    },
    title: "Field Table",

    data() {
        return {
            searchTimeout: 500,
            searchKeyword: "",
            searchLoading: {},
            columns: [],
            // columnFilterOptions: {},
            columnFilterOptionsAsync: {},
            items: [],
            showInputTypeModal: false,
            fieldData: [],
            selectedField: null,
            isLoading: false,
            filterTypevalue: { label: "All fields", value: false },
            FilteredResult: [],
            isCloning: false,
            selectedRow: null,
            fieldFilters: [
                { label: "All fields", value: false },
                { label: "Special fields", value: true },
            ],
            fetchingFieldType: false,
            fieldChecks: null,
            fieldBlocks: null,
            activeRow: null,
            fetchingFieldBlocks: false,
            paginationOptions: {
                enabled: true,
                mode: "remote",
                perPage: 10
            },
            pageData: {
                total: 0,
            },
            currentPageData: {
                page: 1,
                count: 10,
            },
            serverParams: null,
            searchParams: {
                block: "",
                check: "",
            },
            sortParams: null
        };
    },
    computed: {
        fieldDataFiltered() {
            return this.fieldData.length ? this.fieldData.filter((fld) => fld.ui_label.toLowerCase().includes(this.searchKeyword.toLowerCase()) || fld?.ui_label?.split("_").join(" ").toLowerCase().includes(this.searchKeyword.toLowerCase())) : [];
        },
        // getFilteredResult() {
        //     let searchValue = this.filterTypevalue;
        //     let newFields = this.items;
        //     if (searchValue && searchValue === "Special fields") {
        //         newFields = this.items.filter((el) => el.special_type && el.special_type.length);
        //     }
        //     return newFields;
        // },
        filterOptions() {
            let result = this.getFilterOptions(this.columnOptions, this.items);
            return result;
        },
        columnOptions() {
            return [
                { label: "Action", field: "actions", config: { filter: false, type: "actions" },tdClass: "truncate max-w-xs" , sortable: false},
                { label: "Lock", field: "lock", config: { filter: false, type: "lock" , tab:"Field in use" }, tdClass: "truncate max-w-xs" , sortable: false},
                { label: "In Use", field: "in_use", config: { filter: false, type: "tooltip_text" }, tdClass: "truncate max-w-xs" },
                { label: "Name", field: "name", config: { filter: true, type: "link" }, tdClass: "truncate max-w-xs" },
                { label: "Label", field: "label", config: { filter: true, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Checks", field: "checks_count", config: { filter: false, type: "show_info" }, tdClass: "truncate max-w-xs",sortable: false  },
                { label: "Blocks", field: "blocks_count", config: { filter: false, type: "show_blocks" }, tdClass: "truncate max-w-xs", sortable: false },
                { label: "Type", field: "ui_label", query_key: "field_type", prefetchedOptions: this.getTypeOptions, config: { filter: true }, tdClass: "truncate max-w-sm" },
                { label: "Placeholder", field: "placeholder", config: { filter: true }, tdClass: "truncate max-w-sm" },
                {
                    label: "Source",
                    field: "is_custom",
                    query_key: "source",
                    // {name: 'Restricted', value: 'restricted', id: 3 }
                    prefetchedOptions: [
                        { name: "Custom", value: "custom", id: 1 },
                        { name: "System Provided", value: "system_provided", id: 2 },
                        { name: "Restricted", value: "restricted", id: 3 },
                        
                    ],
                    config: { filter: true, type: "source" },
                    tdClass: "truncate max-w-sm",
                    sortable: false
                },
                { label: "Created by", field: "created_by", config: { filter: false, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Created At", field: "created_at", config: { filter: true, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Last Updated By", field: "modified_by", config: { filter: false, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
                { label: "Last Updated At", field: "updated_at", config: { filter: true, type: 'tooltip_text' }, tdClass: "truncate max-w-xs" },
               
            ].map((el) => ({ ...el, config: { ...el.config, isLoading: !!this.searchLoading[el.field] || false } }));
        },

        getTypeOptions() {
            let items = this.fieldData.map((el) => ({ id: el.id, value: el.id, name: el.ui_label }));
            return items;
        },
        getFieldBlocks() {
            return this.fieldBlocks?.filter((el) => el?.name?.toLowerCase().includes(this.searchParams.block.toLowerCase()));
        },
        getFieldChecks() {
            return { ...this.fieldChecks, checks: this.fieldChecks?.checks.filter((el) => Object.entries(el)[0][1]?.toLowerCase()?.includes(this.searchParams.check.toLowerCase())) };
        },
        // fieldData() {
        //     return this.$store.getters.fieldsType;
        // },
        // columnFilterOptions() {
        //     return this.getFilterOptions(
        //         this.columnOptions,
        //         this.items
        //     )
        // }
    },

    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
            // this.tenant_id = this.$store.getters.getTenantId
        }
        this.isLoading = true;
        await this.$store.dispatch(FETCH_FIELDS);
        this.fieldData = this.$store.getters.fieldsType;
        await this.fetchFields();
        // await this.fetchFieldsTypes()
    },
    methods: {
        FormatDateNew,
        checkPermission,
        // async fetchFieldsTypes() {
        //     let result = await axios.get('/fields/types')
        //     this.fieldData = result.data.field_types
        // },

        async fetchFields() {
            this.isLoading = true;
            let filterParams = {};
            const getFilterValue = (key) => {
                if (["field_type", "source"].includes(key)) {
                    return this.serverParams[key]["value"];
                } else return this.serverParams[key]["name"];
            };
            if (this.serverParams) {
                Object.keys(this.serverParams).map((key) => {
                    filterParams[key] = this.serverParams[key] ? getFilterValue(key) : delete filterParams[key];
                });
                if(filterParams.created_at){
                    filterParams.created_at= DateTime.fromFormat(filterParams.created_at, "dd MMM yyyy").plus({ days: 0 }).toISODate();
                    // filterParams.created_at = FormatDateNew(new Date(filterParams.created_at))
                }
                if(filterParams.updated_at){
                    filterParams.updated_at= DateTime.fromFormat(filterParams.updated_at, "dd MMM yyyy").plus({ days: 0 }).toISODate();
                    // filterParams.updated_at = FormatDateNew(new Date(filterParams.updated_at))
                }
            }
            let url = `/fields/${this.$store.getters.getTenantId}/all?page=${this.currentPageData.page || 1}&&count=${this.currentPageData.count || 10}`;

            let { data } = await axios.get(url, { params: { ...filterParams, special_fields: this.filterTypevalue.value || false, ...this.sortParams || ""  } });
            // const formatDate = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
            data.fields = data.fields?.map((item) => {
                if(item.created_at || item.updated_at) {
                    item.created_at = FormatDateNew(new Date(item.created_at));
                    item.updated_at = FormatDateNew(new Date(item.updated_at));
                    return item;
                }
            })
            this.items = data.fields;
            this.pageData = data?.page;
            this.isLoading = false;
            this.columnFilterOptionsAsync = this.getFilterOptions(this.columnOptions, data?.fields);
            for(let i in this.items){
                if(this.items[i]['label'].length>100)
                 this.items[i]['label']=this.items[i]['label'].substring(0,97)+'...'
                 else
                 this.items[i]['label']
            }
        },
        isRestrictedOrCustom(field) {
            if (field.is_custom) {
                return "Custom";
            } else if (field.is_restricted) {
                return "Restricted";
            } else {
                return "System Provided";
            }
        },
        hanldeCloseModal() {
            // this.$modal.hide("field-modal");
            this.$refs["add_field_modal"].hideModal();
        },
        async handleShowModal() {
            this.$refs["add_field_modal"].showModal();
            this.fetchingFieldType = true;
            this.selectedField = null;
            // this.$modal.show("field-modal");
            await this.$store.dispatch(FETCH_FIELDS);
            this.fieldData = this.$store.getters.fieldsType;
            this.fetchingFieldType = false;
        },
        handleSelectField(field) {
            this.selectedField = field;
        },
        handleAddNewField() {
            this.$router.push({
                path: "/add-field",
                query: {
                    field: this.selectedField ? this.selectedField.id : "input-text",
                },
            });
        },
        async onSortChanged(data) {
            if (data && data.length) {
                let prmData = data[0]
                if (prmData.type !== 'none') {
                    console.log('data :>> ', prmData);
                    this.sortParams = {
                        sort: prmData.field + ':' + prmData.type 
                    }
                } else this.sortParams = null 
                await this.fetchFields()
            }  
        },
        closeCloseFieldModal() {
            this.$refs["clone-field-modal"].hideModal();
        },
        showCloneModal(field) {
            if(checkPermission('field.create')){
                this.selectedRow = null;
                this.$refs["clone-field-modal"].showModal();
                this.selectedRow = field;
                let fieldData = this.fieldData.find((ele) => ele.name === this.selectedRow.type);
                this.selectedRow = { ...this.selectedRow, is_cascaded: fieldData.has_options };
            // } else {
            //     this.$toast.error('Access Denied! You are not authorized to perform this operation.')
            }
        },
        getIcon() {
            // return require(`@/assets${this.fieldData.find((ele) => ele.name === field_name).icon}`);
            return require(`@/assets/icons/svg/input-text.svg`);
        },
        handleRowClick(data) {
            this.$router.push({
                name: "Field Setting",
                params: { id: data.id },
            });
        },
        async handleCloneRow(row, cloneType) {
            this.selectedRow = null;
            this.closeCloseFieldModal();
            if (row && cloneType) {
                this.isCloning = true;
                let payload = {
                    deep_clone: cloneType === "deep-clone" ? true : false,
                };
                try {
                    let url = `/fields/${row.id}/clone`;
                    let result = await axios.post(url, payload);
                    let cloneData = result.data;
                    let field_data = await this.fetchFieldData(cloneData.field_id);
                    this.items.push(field_data);
                    this.$router.push({
                        name: "Field Setting",
                        params: { id: field_data.id ,action:"edit"},
                    });
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "failed to clone field");
                }
                this.isCloning = false;
            }
        },
        async fetchFieldData(fieldId) {
            let url = `/fields/${this.$store.getters.getTenantId}/field/${fieldId}`;
            let result = await axios.get(url);
            return result.data;
        },

        filterDataByFieldType(value) {
            if (value) {
                this.filterTypevalue = value;
                this.currentPageData.page = 1;
                this.fetchFields();
            }
        },
        setFieldChecks(payload) {
            this.searchParams.check = "";
            if (payload && payload.checks_count > 0) {
                this.fieldChecks = payload;
                this.$modal.show("fieldChecksModal");
            }
        },
        async setFieldBlocks(payload) {
            if (payload && payload.blocks_count > 0) {
                this.searchParams.block = "";
                this.activeRow = payload;
                this.$modal.show("fieldBlocksModal");
                this.fetchingFieldBlocks = true;
                try {
                    let url = `/fields/${payload.id}/blocks`;
                    let { data } = await axios.get(url);
                    this.fieldBlocks = data.blocks;
                } catch (error) {
                    console.log(">>>>>errow while fetching blocks", error);
                }

                this.fetchingFieldBlocks = false;
            }
        },
        async onPageChange(data, reset = false) {
            let { currentPerPage, currentPage } = data;
            this.currentPageData = {
                page: currentPage,
                count: currentPerPage,
            };
            if (reset) this.currentPageData.page = 1;
            if(!reset) {
                await this.$nextTick();
                const scrollTarget = document.getElementById('fieldTable');
                if (scrollTarget) {
                  scrollTarget.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
            }
            await this.fetchFields();
        },
        async onColumnFilters({ columnFilters }) {
            this.currentPageData.page = 1
            this.paginationOptions.perPage = this.currentPageData.count
            this.serverParams = columnFilters ? { ...columnFilters } : null;
            await this.fetchFields();
        },
        async clearFilters() {
            this.paginationOptions.perPage = this.currentPageData.count
            this.serverParams = null;
            await this.fetchFields();
        },
        getFilterOptions(columns, rows) {
            return columns.reduce((result, curr) => {
                result[curr.field] = rows
                    .filter((row) => row[curr.field])
                    .map((row) => row[curr.field])
                    .filter(onlyUnique)
                    .map((el) => ({ id: uuid.v4(), name: el }));
                return result;
            }, {});
        },

        getSearchOptionsTick(payload) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.columnFilterOptionsAsync = null;
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, payload.column.field, true);
                try {
                    const requestData = {
                        ...this.serverParams,
                        [payload.column.query_key || payload.column.field]: payload.value,
                    };
                    const { data } = await axios.get(`fields/${this.$store.getters.getTenantId}/all`, {
                        params: requestData,
                    });
                    this.columnFilterOptionsAsync = this.getFilterOptions(this.columnOptions, data.fields);
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, payload.column.field, false);
            }
        },
        handleFilterResult(data, type) {
            this.searchParams[type] = data;
        },
    },
};
